<script>
import appConfig from "@/app.config";

/**
 * Coming-soon component
 */
export default {
  layout: "auth",
  page: {
    title: "Coming-soon",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Coming-soon",
      start: "12",
      end: "24",
      interval: "07",
      days: "07",
      minutes: "59",
      hours: "23",
      seconds: "59",
      starttime: "Mar 11, 2022 15:37:25",
      endtime: "Mar 31, 2022 16:37:25",
    };
  },
  mounted() {
    document.body.classList.add('authentication-bg')

    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/shiftFish-logo.png"
                  alt
                  height="22"
                  class="logo logo-dark"
                />
               
              </router-link>

              <div class="row justify-content-center mt-5">
                <div class="col-lg-4 col-sm-5">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/coming-soon-img.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>

              <h4 class="mt-5">Feature is coming Soon!</h4>
              <small class=" text-muted">
                 We're creating something awesome
              </small>
              <p class="text-muted mt-2">
                We are Currently working on this feature and will launch soon!
              </p>

              <div class="row justify-content-center mt-5">
                <div class="col-lg-10">
                  <div data-countdown="2020/12/31" class="counter-number">
                    <div class="coming-box">
                      {{ days }}
                      <span>Days</span>
                    </div>
                    <div class="coming-box">
                      {{ hours }}
                      <span>Hours</span>
                    </div>
                    <div class="coming-box">
                      {{ minutes }}
                      <span>Minutes</span>
                    </div>
                    <div class="coming-box">
                      {{ seconds }}
                      <span>Seconds</span>
                    </div>
                  </div>
                </div>
                <!-- end col-->
              </div>
              <!-- end row-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
